import logo from './logo.svg';
import './App.css'; // Importando o arquivo CSS existente

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          <b>DanTi Digital</b> - Tecnologia e Marketing 
        </p>
        <p className="animated-text">
          Fechado para novos clientes no momento.
        </p>
        <p className="contact-info">
          <br/>
          <span>  
            <br/>dantidigital@gmail.com
            <br/>
            <a
              href="https://wa.me/5561981158709"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#25D366', textDecoration: 'none', fontSize: '1.2rem', marginTop: '20px', display: 'inline-block' }}
            >
              WhatsApp: (61) 9 8115-8709
            </a>
          </span>
        </p>
      </header>
    </div>
  );
}

export default App;
